import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import styles from "./404.module.scss"

const ErrorPage = () => {
  return (
    <Layout>
      <Seo title="404: Page non trouvée" />
      <main className={styles.mainContainer}>
        <article className={styles.articleContainer}>
          <h1>Oups !</h1>
          <p>La page que vous recherchez n'existe pas.</p>
          <Link to="/">Retour à l'accueil</Link>
        </article>
      </main>
    </Layout>
  )
}

export default ErrorPage
